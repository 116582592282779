import $ from "jquery";
$('.cu-part__expanders.accordion .expander .expander-header').on('click', function(e) {
    e.preventDefault();

    $(this).toggleClass('active').parent().find('.expander-body').slideToggle(400);
});

$('.cu-part__expanders.tabs .expander .expander-header').on('click', function(e) {
    e.preventDefault();
    var thisID = $(this).parent().attr('id');
    $(this).parent().siblings().not($(this).parent().attr('id')).find('.expander-header.active').toggleClass('active').parent().find('.expander-body').slideUp();
    $(this).toggleClass('active').parent().find('.expander-body').slideToggle(400);
    console.log(thisID);
    $('.tablinks').removeClass("tabhighlight");
    $("#btn-" + thisID).addClass("tabhighlight");

});

$('.tablinks').on('click', function(e) {
    e.preventDefault();
    var tabbtnID = $(this).attr('id');
    var targetID = tabbtnID.slice(4);
    var tabsID = $(this).attr('tabsid');
    
    if (jQuery ('#'+ targetID + ' .expander-header').hasClass('active')){console.log ("Active");

    } else {

  jQuery('.'+tabsID+'.expander-header.active').not('#' + targetID +' .expander-header').toggleClass('active').parent().find('.expander-body').slideUp();
  jQuery('#' + targetID +' .expander-header').toggleClass('active').parent().find('.expander-body').slideToggle(400);
  $('.tablinks.' + tabsID ).removeClass("tabhighlight");
  $(this).addClass("tabhighlight");
    }
});


$(document).ready(function(){
  $('.firsttab .expander-header').toggleClass('active').parent().find('.expander-body').slideToggle(400);
  $('.firsttabbutton').addClass("tabhighlight");
});











      


